import * as React from 'react';
import Layout from '../Components/Layout/Layout';
import Contact from '../Components/Contact/Contact';
import SEO from '../Components/seo';

// markup
const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <Layout page="Contact">
      <main id="main">
        <Contact />
      </main>
    </Layout>
  </>

);

export default ContactPage;
