/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './contact.css';

const Contact = () => (
  <section id="contact" className="wow fadeInUp">
    <div className="container">
      <div className="row contact-info">

        <div className="col-md-4">
          <div className="contact-address">
            <FaMapMarkerAlt />
            <h3>Address</h3>
            <address>11958 Mapungubwe Street, Ivory Park ext 10 1685, RSA</address>
          </div>
        </div>

        <div className="col-md-4">
          <div className="contact-phone">
            <FaPhone />
            <h3>Phone Number</h3>
            <p><a href="tel:+27767745459">+27 76 774 5459</a></p>
          </div>
        </div>

        <div className="col-md-4">
          <div className="contact-email">
            <FaEnvelope />
            <i className="fa fa-envelope" />
            <h3>Email</h3>
            <p><a href="mailto:info@sheisbrave.org.za">info@sheisbrave.org.za</a></p>
          </div>
        </div>

      </div>
    </div>

    <div className="container">

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28688.767326011246!2d28.175657830113686!3d-25.997675222860373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95695352ea2c6b%3A0x73bc42e0eb49cd48!2sIvory+Park%2C+Midrand%2C+1689!5e0!3m2!1sen!2sza!4v1536235888838"
        frameBorder="0"
        style={{ border: 0 }}
        width="100%"
        allowFullScreen
        title="Ivory Park Map"
      />

    </div>
  </section>
);

export default Contact;
